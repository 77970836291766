import {store, useStore} from "../../store";
import {useState} from "react";
const countDirectionDefault = (direction, max) => {
    const maxLeftAndRight = 2;
    let maxForOne =  max / 4;
    if (maxForOne <= 1) {
        direction.up = 1;
        max = max - 1;
        direction.left = max ? 1 : 0;
        max = max - 1;
        direction.right = max ? 1 : 0;
        max = max - 1;
        direction.bottom = max ? 1 : 0;
        return direction;
    }

    if (maxForOne >= 2) {
        max = max - maxLeftAndRight * 2;
        const up = Math.floor(max/2);
        max = max - up;
        direction.left = 2;
        direction.right = 2;
        direction.up = up;
        direction.bottom = max;
        return direction;
    }

    max = max - maxLeftAndRight * 2;
    maxForOne = Math.floor(maxForOne);
    const rest = max - maxForOne;
    direction.up = maxForOne + rest;

    direction.left = maxForOne;
    direction.right = maxForOne;

    direction.bottom = maxForOne;
    return direction;
}

const EditNipples = () => {
    const  {totals, selectDevice, device, nextStep}  = useStore(store, (state) => state);
    ///boki max 2
    //suma tyle co w obliczeniach
    const maxBox = totals.blowingBox + totals.exhaustBox;

    const [direction, setDirection] = useState(countDirectionDefault({up: 0, left: 0, right: 0, bottom: 0}, maxBox));


    const checkSum = (oneDirection, max) => {
        const values = Object.values({...direction, ...oneDirection});
        const sum = values.reduce((accumulator, value) => {
            return accumulator + value;
        }, 0);

        console.log("===values", values, sum)

        return sum <= maxBox
    }

    const inputOnChange = (event, max) => {
        console.log("====event.target.value", event.target.value)
        if (event.target.value > max) {
            console.log("====max2 error")
            return {error: "max 2"}
        }
        if (!checkSum({[event.target.name]: parseInt(event.target.value)})) {
            console.log("====przekroczono max liczbe króców")
            return {error: "przekroczono max liczbe króców"}
        }

        setDirection((prevState) => {
            return {...prevState,  [event.target.name]: parseInt(event.target.value)}
        })
    }


    return <>
        <div className="alignCenter">Wpisz ilość króćców przy poszczególnych bokach rozdzielacza</div>
        <div className="rozdzielaczInput"><input  value={direction.up} name={"up"} onChange={inputOnChange}/></div>

        <div className="flex flexVcenter">
            <div className="rozdzielacz1 rozdzielaczInput"> <input  value={direction.left} name={"left"} onChange={(event) => inputOnChange(event, 2)}/></div>
            <div className="rozdzielacz2"><img src="krocce.jpg" alt={"krocce"}/></div>
            <div className="rozdzielacz1 rozdzielaczInput"><span>prawa</span> <input  value={direction.right} name={"right"} onChange={(event) => inputOnChange(event, 2)}/></div>
        </div>

        <div className="rozdzielaczInput"> <input  value={direction.bottom} name={"bottom"} onChange={inputOnChange}/></div>


        <div className={"flex"}>
            <button onClick={() => {nextStep(3)}} >wstecz</button>
            <button onClick={() => { nextStep(5)}} >Dobierz produkty</button>
        </div>


    </>
}

export default EditNipples;
