import './index.css';
import CreateRoom from "./components/createRoom";
import CreateProject from "./components/CreateProject";
import "./components/CreateProject";
import {store, useStore} from "./store";
import AddedRooms from "./components/AddedRooms";
import CountedRooms from "./components/CountedRooms";
import Distributor from "./components/Distributor";
import EditDistributor from "./components/Distributor/edit";
import ShowProducts from "./components/ShowProducts";
import Pdf from "./components/PDF";
function App() {

    const  {step, storeys, countStoreys, nextStep}  = useStore(store, (state) => state);

  return (
      <section id="primary" className="normalPage">
          <div class={"box"}>
              {/*<Pdf />*/}
              <div className="titleWrapWrap">
                  <div className="nag"><h1>Program doboru</h1></div>
              </div>

                <div id="kalkulator">
                    {step === 0 && <CreateProject /> }
                    {
                        step === 1 && storeys.map(storey => {
                            return <div>
                                <div className="titleWrapWrap">
                                    <div className="nag"><h2>Dodaj pomieszczenie do kondygnacji: {storey.name}</h2></div>
                                </div>
                                <CreateRoom storey={storey}/>
                                <AddedRooms storey={storey}/>
                            </div>
                        })
                    }

                    { step === 2 && <CountedRooms />}

                    { step === 3 && <Distributor />}

                    { step === 4 && <EditDistributor />}


                    { step === 5 && <ShowProducts />}

                    <div className={"flex"}>
                        { (step > 0 &&  step < 3) && <button  style={{marginTop: 100}} onClick={() => {nextStep(step-1)}}>wstecz</button>}
                        {
                            step === 1 && <button  style={{marginTop: 100}} onClick={() => {countStoreys(); nextStep(2)}} >Przelicz</button>
                        }
                        {
                            step === 2 && <button  style={{marginTop: 100}} onClick={() => {countStoreys(); nextStep(3)}} >Wybór zasilania</button>
                        }
                    </div>




                </div>
          </div>
      </section>
  );
}

export default App;
