import RoomsSelectList from "../RoomsSelectList";
import optionsTest from "../RoomsSelectList/data/roomsList";
import {useEffect, useState} from "react";
import RoomArea from "../RoomArea";
import RoomsList from "../RoomsSelectList/data/roomsList";
import AddedRooms from "../AddedRooms";
import {store, useStore} from "../../store";
import "../CreateProject";
const UpdateRoom = ({room, storeyId, setEditId}) => {
// z listy activ numer of people
    // tu przekzac dane do listy
    const  {storeys, updateRoom}  = useStore(store, (state) => state);


    const [selectedOption, setSelectedHandle] = useState(optionsTest.filter(option => option.name === room.roomName)?.[0] ?? "");
    const [state, setState] = useState(  {area: room.area, roomName: room.roomName, height: room.height, numberOfPeople: room.numberOfPeople});
    const [inputsState, setInputStates] = useState({length: "", width: "", height: room.height, numberOfPeople: room.numberOfPeople ?? "", area: room.area, disabledWidthHeight: false});

    useEffect( () => {
        if (selectedOption) {
            setState({...state, roomName: selectedOption?.name});;
        }
    }, [selectedOption]);

    useEffect( () => {
    }, [state]);
    console.log("=====selectedOption", state);

    const inputOnChange = (event) => {
        if (event.target.validity.valid) {
            setState({...state, [event.target.name]: event.target.value})
        }
    }

    const updateRoomLocal = (e) => {
        //setRooms([...rooms, state])
        console.log("====updateRoomLocal", storeyId, state, e.target.height.value)
        e.preventDefault();
        updateRoom({storeyId, room: {...state, id: room.id, height: e.target.height.value, area: e.target.area.value}});
        setEditId(null);
        // setState({area: null, roomName: null, height: storey.height, numberOfPeople: "",});
        // setInputStates({length: "", width: "", height: storey.height, numberOfPeople: "", area: "", disabledWidthHeight: false})
        // setSelectedHandle( {id: 0, name: "Rodzaj pomieszczennia"})
        e.target.reset();


    }

    return <>
            <form onSubmit={updateRoomLocal} className={"divTableRow"}>

                    <div className="divTableCell">
                    <RoomsSelectList options={optionsTest} setSelectedHandle={setSelectedHandle} defaultValue={optionsTest.findIndex(option => option.name === room.roomName)}/>
                    </div>
                    <RoomArea setState={setState} state={state} selectedOption={selectedOption} inputsState={inputsState} setInputStates={setInputStates} isDisable={selectedOption.id === 0} isEdit={true} />
                    <div className={"divTableCell"}></div>

                    <div className={"divTableCell"}>
                        <button type={"submit"} disabled={selectedOption.id === 0 || inputsState.area.length === 0 || inputsState.height.length === 0} className={"button"}>zapisz</button>
                    </div>
                <div className={"divTableCell"}></div>
                <div className={"divTableCell"}></div>

            </form>
            {/*stad tylko area do glownego*/}
            {/*<input name={"roomName"} onChange={inputOnChange} placeholder={"nazwa pokoju"}/>*/}



    </>
}



export default UpdateRoom;
