import {useState} from "react";

const percent = (num, per) => {

    per = parseFloat(per);

  if (isNaN(per)) return num;

    const result  = num - (num / 100) * per;
    if (result < 0) {
        return 0;
    }
  return   num - (num / 100) * per;

}
const Product = ({product, changeProducts}) => {
    const [discount, setDiscount] = useState("");
    const [qty, setQty] = useState(+product.qty);

    return <>
        <div className="divTableCell">{product.name}</div>
        <div className="divTableCell">{product.description}</div>
        {/*<div>{product?.group}</div>*/}
        {/*<div>{product?.discount}</div>*/}
        <div className="divTableCell">
        <input value={discount} onChange={(event)=> {

            setDiscount( event.currentTarget.value)
            product.discount = event.currentTarget.value;
            product.qty = qty;
            changeProducts(product);
        }}/>
        </div>
        <div className="divTableCell"><input value={qty} onChange={(event)=> {

            setQty( event.currentTarget.value);
            product.discount = discount;
            product.qty = event.currentTarget.value;
            changeProducts(product);
        }}/></div>
        <div className="divTableCell">{percent(+product?.price, discount)}</div>
        <div className="divTableCell">{percent(+product?.price, discount) * +qty}</div>
    </>
};

export default Product;
