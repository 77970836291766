import {store, useStore} from "../../store";
import Product from "./Product";
import distributors from "../../mock/distributors.json";
import productsData from "../../mock/products.json";
import SelectGroup from "./selectGroup";

import {useState} from "react";

const percent = (num, per) => {

    per = parseFloat(per);

    if (isNaN(per)) return num;
    console.log('====num', num, per);
    const result  = num - (num / 100) * per;
    if (result < 0) {
        return 0;
    }
    return   num - (num / 100) * per;

}
const ShowProducts = () => {
    const  {totals, nextStep}  = useStore(store, (state) => state);

    const groupsDiscount = [{id: 0, name: "Wybierz grupę"}, {id: 1, name: "GR1"}, {id: 2, name: "GR2"}, {id: 3, name: "GR3"}, {id: 4, name: "GR4"}];

    const maxBox = totals.blowingBox + totals.exhaustBox;
    const products = distributors?.[maxBox]?.map(distributor => ({...productsData.find(product => product.id === distributor.id), qty: distributor.qty})) ?? [];

    console.log("=====maxbox", maxBox, products);
    const [selectedOption, setSelectedHandle] = useState(groupsDiscount[0]);
    const [productsTemporary, setProductsTemporary] = useState(products);
    const changeProducts = (product) => {
        const foundIndex = productsTemporary.findIndex(productTemp => productTemp.id === product.id);

        if (foundIndex !== -1) {
          //  console.log("====product", foundIndex, product);
            const temp = [...productsTemporary];
            temp[foundIndex] = {...product};
            setProductsTemporary(temp);
        }
    }
    console.log("=====", productsTemporary);
    return <>
        <div className="titleWrapWrap">
            <div className="nag"><h2>Dobrane produkty</h2></div>
        </div>

        <div className="titleWrapWrap"><div className="nag"><h3>Grupa rabatowa</h3></div> <SelectGroup options={groupsDiscount} setSelectedHandle={setSelectedHandle} /></div>

        <div className="divTable">
            <div className="divTableHeading">
                <div className="divTableRow">
                    <div className="divTableHead">Symbol</div>
                    <div className="divTableHead">Nazwa</div>
                    <div className="divTableHead">Rabat %</div>
                    <div className="divTableHead">Ilość</div>
                    <div className="divTableHead">Cena sztuka</div>
                    <div className="divTableHead">Cena suma</div>
                </div>
            </div>


            <div className="divTableBody">
            {products.map(product =>  <div className="divTableRow" key={product.id}><Product product={product} changeProducts={changeProducts}/></div>)}
            </div>

        </div>

        <div className="titleWrapWrap">
            <div className="nag"><h3>Podsumowanie</h3></div>
        </div>

        <div className="divTable">
            <div className="divTableHeading">
                <div className="divTableRow hidden">
                    <div className="divTableHead">Symbol</div>
                    <div className="divTableHead">Nazwa</div>
                    <div className="divTableHead">Rabat</div>
                    <div className="divTableHead">Ilość</div>
                    <div className="divTableHead">Cena sztuka</div>
                    <div className="divTableHead">Cena suma</div>
                </div>
            </div>
            <div className="divTableBody summary">
                <div className="divTableCell"></div>
                <div className="divTableCell"></div>
                <div className="divTableCell"></div>
                <div className="divTableCell"></div>
                <div className="divTableCell"></div>
                <div className="divTableCell">{productsTemporary.reduce((accumulator, currentValue) => {
                    return (parseFloat(accumulator) + (percent(currentValue.price, currentValue.discount)* +currentValue.qty))}, 0)}</div>
            </div>
        </div>

        <div className={"flex"}>
            <button onClick={() => {nextStep(4)}} >wstecz</button>
        <button  onClick={() => {
            //makePdf();

        }} >Pobierz pdf</button>

        </div>
    </>
}

export default ShowProducts;
