import {useEffect, useRef, useState} from "react";
import {types} from "../../types";

const RoomArea = ({setState, state, selectedOption, inputsState, setInputStates, isDisable, isEdit}) => {
        const inputOnChange = (event) => {
            let localState = inputsState;
            if (event.target.name === "area") {
                localState = {...localState, width: "", length: "", disabledWidthHeight: true};

                if (event.target.value === "") {
                    localState = {...localState, disabledWidthHeight: false}
                }
                const isBlowing = selectedOption.type === types.BLOWING;
                let exhaustProps;
                if (!isBlowing) {
                    exhaustProps = {replacementValue: selectedOption.replacementValue, value: selectedOption.value}
                }

                setState({...state, area: event.target.value, height: inputsState.height, numberOfPeople: inputsState.numberOfPeople, roomId: selectedOption.id, type: selectedOption.type, ...exhaustProps});

            }

            if (event.target.validity.valid) {
                setInputStates({...localState, [event.target.name]: event.target.value})
            }
        }

        const countExhaust = ({volume}) => {
            const  {replacementValue, value} = selectedOption;
            if (volume*replacementValue <= value) {
                return value
            }

            return volume*replacementValue
        }

        const countBlowing = ({numberOfPeople, volume}) => {

            const result = numberOfPeople * 30;

            if (result > volume) {
                return result
            }
            return volume;
        }



    useEffect(() => {
        if (inputsState.width && inputsState.length) {
            const volume =  inputsState.width * inputsState.length * inputsState.height;
            const isBlowing = selectedOption.type === types.BLOWING;

            const blowing = countBlowing({volume, numberOfPeople: inputsState.numberOfPeople});
            const exhaust = !isBlowing && countExhaust({volume});
            const exhaustOrBlowing = isBlowing ? {blowing}  : {exhaust};
            const replacement = isBlowing ?  (blowing / volume) : (exhaust / volume);

            let exhaustProps;
            if (!isBlowing) {
                exhaustProps = {replacementValue: selectedOption.replacementValue, value: selectedOption.value}
            }

            setInputStates({...inputsState, area: inputsState.width * inputsState.length, volume, ...exhaustOrBlowing, replacement });
            setState({...state, area: inputsState.width * inputsState.length, height: inputsState.height, numberOfPeople: inputsState.numberOfPeople, roomId: selectedOption.id, type: selectedOption.type, ...exhaustProps});
        }
    }, [inputsState.width, inputsState.length, inputsState.height, inputsState.numberOfPeople])


    return <>
        {!isEdit && <><div className="divTableCell">
            <input pattern="[0-9].*" onChange={inputOnChange} value={inputsState.length} name="length"  data-test="room-length"  placeholder={"długość"} disabled={inputsState.disabledWidthHeight || isDisable}/>
        </div>
        <div className="divTableCell">
            <input pattern="[0-9].*" onChange={inputOnChange} value={inputsState.width} name="width" data-test="room-width" placeholder={"szerokość"} disabled={inputsState.disabledWidthHeight || isDisable}/>
        </div></>}
        <div className="divTableCell">
            <input pattern="[0-9].*" onChange={inputOnChange} value={inputsState.height} name="height"  data-test="room-height" placeholder={"wysokość"} disabled={isDisable} />
        </div>
        <div className="divTableCell">
            <input pattern="[0-9].*" onChange={inputOnChange} value={inputsState.area} name="area" data-test="room-area" placeholder={"powierzchnia"} disabled={isDisable}/>
        </div>
        <div className="divTableCell">
            {!selectedOption?.lockNumberOfPeople && <input  pattern={"[0-9].*"} onChange={inputOnChange} value={inputsState.numberOfPeople} name={"numberOfPeople"}  placeholder={"liczba osób"} disabled={selectedOption?.lockNumberOfPeople || isDisable}/>}
        </div>
        {/*<input pattern="[0-9].*" onChange={inputOnChange} value={inputsState.volume} name="volume"  data-test="room-height" placeholder={"kubatura"}/>*/}
        {/*<input pattern="[0-9].*" onChange={inputOnChange} value={inputsState.blowing} name="blowing" data-test="room-area" placeholder={"wywiew"}/>*/}
        {/*<input pattern="[0-9].*" onChange={inputOnChange} value={inputsState.exhaust} name="exhaust" data-test="room-area" placeholder={"nawiew"}/>*/}
        {/*<input pattern="[0-9].*" onChange={inputOnChange} value={inputsState.replacement} name="replacement" data-test="room-area" placeholder={"replacment"}/>*/}

    </>
}

const RoomAreaCount = ({setState, state, selectedOption}) => {
    console.log("====selectedOption", selectedOption)
    const [inputsState, setInputStates] = useState({length: "", width: "", height: "", numberOfPeople: "", area: "", blowing: "", exhaust: "", replacement: "", volume: "", disabledWidthHeight: false});
    const inputOnChange = (event) => {
        let state = inputsState;
        if (event.target.name === "area") {
            state = {width: "", length: "", disabledWidthHeight: true};

            if (event.target.value === "") {
                state = {...state, disabledWidthHeight: false}
            }
        }

        if (event.target.validity.valid) {
            setInputStates({...state, [event.target.name]: event.target.value})
        }
    }

    const countExhaust = ({volume}) => {
        const  {replacementValue, number} = selectedOption;
        if (volume*replacementValue <= number) {
            return number
        }

        return volume*replacementValue
    }

    const countBlowing = ({numberOfPeople, volume}) => {

        const result = numberOfPeople * 30;
        console.log("===numberOfPeople", numberOfPeople, volume, result);
        if (result > volume) {
            return result
        }
        return volume;
    }

    useEffect(() => {
        if (inputsState.width && inputsState.length) {
            const volume =  inputsState.width * inputsState.length * inputsState.height;
            const isBlowing = selectedOption.type === "nawiew";
            console.log("=====volume", volume)
            const blowing = countBlowing({volume, numberOfPeople: inputsState.numberOfPeople});
            const exhaust = !isBlowing && countExhaust({volume});
            const exhaustOrBlowing = isBlowing ? {blowing}  : {exhaust};
            const replacement = isBlowing ?  (blowing / volume) : (exhaust / volume);
            console.log("====replacement", replacement, volume, exhaust, blowing);
            setInputStates({...inputsState, area: inputsState.width * inputsState.length, volume, ...exhaustOrBlowing, replacement });
            setState({...state, area: inputsState.width * inputsState.length});
        }
    }, [inputsState.width, inputsState.length, inputsState.height, inputsState.numberOfPeople])


    return <>
        <input pattern="[0-9].*" onChange={inputOnChange} value={inputsState.length} name="length"  data-test="room-length"  disabled={inputsState.disabledWidthHeight}/>
        <input pattern="[0-9].*" onChange={inputOnChange} value={inputsState.width} name="width" data-test="room-width" disabled={inputsState.disabledWidthHeight}/>
        <input pattern="[0-9].*" onChange={inputOnChange} value={inputsState.height} name="height"  data-test="room-height" />
        {selectedOption?.lockNumberOfPeople && <input  pattern={"[0-9].*"} onChange={inputOnChange} value={inputsState.numberOfPeople} name={"numberOfPeople"} disabled={selectedOption?.lockNumberOfPeople}/>}
        <input pattern="[0-9].*" onChange={inputOnChange} value={inputsState.area} name="area" data-test="room-area" />
        <input pattern="[0-9].*" onChange={inputOnChange} value={inputsState.volume} name="volume"  data-test="room-height" placeholder={"kubatura"}/>
        <input pattern="[0-9].*" onChange={inputOnChange} value={inputsState.blowing} name="blowing" data-test="room-area" placeholder={"wywiew"}/>
        <input pattern="[0-9].*" onChange={inputOnChange} value={inputsState.exhaust} name="exhaust" data-test="room-area" placeholder={"nawiew"}/>
        <input pattern="[0-9].*" onChange={inputOnChange} value={inputsState.replacement} name="replacement" data-test="room-area" placeholder={"replacment"}/>

    </>
}


export default RoomArea;
