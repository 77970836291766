import RoomsSelectList from "../RoomsSelectList";
import optionsTest from "../RoomsSelectList/data/roomsList";
import {useEffect, useState} from "react";
import RoomArea from "../RoomArea";
import {store, useStore} from "../../store";
import "../CreateProject";
const CreateRoom = ({storey}) => {
// z listy activ numer of people
    // tu przekzac dane do listy
    const  {storeys, addRoomToStorey}  = useStore(store, (state) => state);

    console.log("====storey", storey, storeys);


    const [selectedOption, setSelectedHandle] = useState({id: 0, name: "Rodzaj pomieszczennia"});
    const [state, setState] = useState(  {area: null, roomName: null, height: storey.height, numberOfPeople: "", roomId: ""});
    const [inputsState, setInputStates] = useState({length: "", width: "", height: state.height, numberOfPeople: "", area: "", disabledWidthHeight: false});

    useEffect( () => {
       if (selectedOption) {
           setState({...state, roomName: selectedOption?.name});
       }
    }, [selectedOption]);


    const inputOnChange = (event) => {
        if (event.target.validity.valid) {
            setState({...state, [event.target.name]: event.target.value})
        }
    }

    const addRoom = (e) => {
        e.preventDefault();
        console.log("====state przed add room", state)
        addRoomToStorey({idStorey: storey.id, room: state});
        setState({area: null, roomName: null, height: storey.height, numberOfPeople: "", roomId: ""});
        setInputStates({length: "", width: "", height: storey.height, numberOfPeople: "", area: "", disabledWidthHeight: false})
        setSelectedHandle( {id: 0, name: "Rodzaj pomieszczennia"})
        e.target.reset();
    }

    return <>
        <div className="divTable">
            <div className="divTableHeading">
                <div className="divTableRow">
                    <div className="divTableHead">Wybierz pomieszczenie</div>
                    <div className="divTableHead">Długość [m]</div>
                    <div className="divTableHead">Szerokość [m]</div>
                    <div className="divTableHead">Wysokość [m]</div>
                    <div className="divTableHead">Powierzchnia [m]</div>
                    <div className="divTableHead">Liczba osób</div>
                    <div className="divTableHead"></div>
                </div>
            </div>


                <form onSubmit={addRoom} className="divTableBody">
                    <div className={"divTableRow"}>
                        <div className="divTableCell"><RoomsSelectList options={optionsTest} setSelectedHandle={setSelectedHandle}/></div>
                        <RoomArea setState={setState} state={state} selectedOption={selectedOption} inputsState={inputsState} setInputStates={setInputStates} isDisable={selectedOption.id === 0} />
                    <div className={"divTableCell"}>
                        <button type={"submit"} disabled={selectedOption.id === 0 || inputsState.area.length === 0} className={"button"}>Dodaj pomieszczenie</button>
                    </div>
                    </div>
                </form>



        </div>
        <div>
            {/*<form onSubmit={addRoom}>*/}
            {/*    <div className={"flex-row"}>*/}
            {/*        <RoomsSelectList options={optionsTest} setSelectedHandle={setSelectedHandle}/>*/}
            {/*        <RoomArea setState={setState} state={state} selectedOption={selectedOption} inputsState={inputsState} setInputStates={setInputStates} isDisable={selectedOption.id === 0} />*/}
            {/*    </div>*/}
            {/*    <div className={"button"}>*/}
            {/*        <button type={"submit"} disabled={selectedOption.id === 0} className={"button"}>Dodaj pomieszczenie</button>*/}
            {/*    </div>*/}
            {/*</form>*/}
            {/*stad tylko area do glownego*/}
            {/*<input name={"roomName"} onChange={inputOnChange} placeholder={"nazwa pokoju"}/>*/}

        </div>

    </>
}



export default CreateRoom;
