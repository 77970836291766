import {useEffect, useState} from "react";
import {store, useStore} from "../../store";
import UpdateRoom from "../createRoom/UpdateRoom";

const AddedRooms = ({storey}) => {
    const {rooms = []} = storey;
    const  {removeRoom}  = useStore(store, (state) => state);

    const [editId, setEditId] = useState(null);
    if (rooms.length === 0) return null;
    return <>
        <div className="titleWrapWrap">
            <div className="nag"><h2>Lista pomieszczeń dla kondygnacji: {storey.name}</h2></div>
        </div>
        <div className="divTable">
            <div className="divTableHeading">
                <div className="divTableRow">
                    <div className="divTableHead">Nazwa pokoju</div>
                    <div className="divTableHead">Wysokość [m]</div>
                    <div className="divTableHead">Powierzchnia [m]</div>
                    <div className="divTableHead">Liczba osób</div>
                    <div className="divTableHead">Edytuj</div>
                    <div className="divTableHead">Usuń</div>
                    <div className="divTableHead">Id:</div>
                    <div className="divTableHead">Typ</div>
                </div>
            </div>
            <div className="divTableBody">
                {rooms?.map(room =>  { return room.id === editId ? <UpdateRoom room={room} storeyId={storey.id} setEditId={setEditId} /> :
                    (<div className={"divTableRow"} key={room.id}>
                        <div className="divTableCell">
                            {room.roomName}
                        </div>
                        <div className="divTableCell">
                            {room.height}
                        </div>
                        <div className="divTableCell">
                            {room.area}
                        </div>
                        <div className="divTableCell">
                            {room.numberOfPeople}
                        </div>
                        <div className="divTableCell">
                            <button onClick={() => setEditId(room.id)}>Edytuj</button>
                        </div>
                        <div className="divTableCell">
                            <button onClick={() => removeRoom({roomId: room.id, storeyId: storey.id})}>Usuń</button>
                        </div>
                        <div className="divTableCell">
                            {room.id}
                        </div>
                        <div className="divTableCell">
                            {room.type}
                        </div>
                </div>)
                })}
            </div>
        </div>
        {/*{rooms?.map(room =>*/}
        {/*    <div key={`room${room.id}`}  className={"flex-row"}>*/}
        {/*            {room.id === editId  ? <UpdateRoom room={room} storeyId={storey.id} setEditId={setEditId} />*/}
        {/*            :*/}
        {/*        <>*/}
        {/*        <div className={"flex-row"}><span>{room.roomName}</span> <span>{room.area}</span> <span>{room.height}</span> <span>{room.id}</span> <span>{room.numberOfPeople}</span><span>{room.type}</span></div>*/}
        {/*        <button onClick={() => setEditId(room.id)}>Edytuj</button> <button onClick={() => removeRoom({roomId: room.id, storeyId: storey.id})}>Usuń</button></>}*/}
        {/*    </div>)}*/}
    </>
}


const EditRoom = ({storey, setEditId}) => {

    const  {editStorey}  = useStore(store, (state) => state);
    const editStoreyLocal = (e) => {
        e.preventDefault();
        editStorey({id: storey, name: e.target.name.value, height: e.target.height.value})
        setEditId(null);
        e.target.reset();
    }
    const [inputsState, setInputStates] = useState({name: storey.name, height: storey.height});

    const inputOnChange = (event) => {
        setInputStates({...inputsState, [event.target.name]: event.target.value})
    }

    return <div>
        <form onSubmit={editStoreyLocal} className={"flex-row"}>
            <input name="name" onChange={inputOnChange} id="name" data-test="storeyName" value={inputsState.name} /> <input  onChange={inputOnChange} id="height" name="height" data-test="storeyHeight" value={inputsState.height}/>

            <button type={"submit"} >Zapisz</button>
        </form>
    </div>
}


export default AddedRooms;
