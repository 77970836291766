import {store, useStore} from "../../store";
import './index.css';
import {useState} from "react";

const CountedRooms = () => {

    const  {storeysCounted, totals, updateKr}  = useStore(store, (state) => state);

    return <div>

        <div className="titleWrapWrap">
            <div className="nag"><h2>Obliczenia</h2></div>
        </div>

        {storeysCounted.map((storey, index) => {
            return <div key={storey.name+index}>
                <StoreyHeader name={storey.name}/>
                <StoreyLine rooms={storey.rooms} storeyId={storey.id} updateKr={updateKr}/>
                <StoreyTotal storey={storey}/>
                </div>
        })}
        {storeysCounted.length > 1 && <StoreysTotal totals={totals}/>}
    </div>


};

const SelectKR = (value, initialValue, room, storeyId, updateKr) => {

        if (!value) return null;

        const options = initialValue > 1  ? [2,3] : [1,2,3];
        const optionMapped = options.map((option, index) => <option key={index} value={index}>{option}</option>);

        return <select name="selectKr" id="kr" onChange={(event) => {
            updateKr({storeyId, roomId: room.id, value: options[event.target.value]})
        }}>
            {optionMapped}
        </select>

}

const StoreyHeader = ({name}) => {

    return <>
        <div className="titleWrapWrap">
            <div className="nag"><h3>Nazwa kondygnacji: {name}</h3></div>
        </div>

        {/*<div className={"flex-row"}>*/}
        {/*    <span>Nazwa pomieszczenie</span>*/}
        {/*    <span>Powierzchnia pomieszczenia </span>*/}
        {/*    <span>Kubatura pomieszczenia</span>*/}
        {/*    <span>Nawiew</span>*/}
        {/*    <span>Skrzynki rozprężne nawiew</span>*/}
        {/*    <span>Wywiew</span>*/}
        {/*    <span>Skrzynki rozprężne wywiew</span>*/}
        {/*    <span>Krotność wymiant</span>*/}
        {/*</div>*/}
        </>
}
const addTextKr = (value) => {
    if (value === undefined) return "";
    return `${value} kr.`
};
const StoreyLine = ({rooms, storeyId, updateKr}) => {

    return <>
        <div className="divTable">
            <div className="divTableHeading">
                <div className="divTableRow">
                    <div className="divTableHead">Nazwa pomieszczenie</div>
                    <div className="divTableHead">Powierzchnia pomieszczenia</div>
                    <div className="divTableHead">Kubatura pomieszczenia</div>
                    <div className="divTableHead">Nawiew</div>
                    <div className="divTableHead">Skrzynki rozprężne nawiew</div>
                    <div className="divTableHead">Wywiew</div>
                    <div className="divTableHead">Skrzynki rozprężne wywiew</div>
                    <div className="divTableHead">Krotność wymiany</div>
                </div>
            </div>
            <div className="divTableBody">
                {rooms.map((room, index) => {
                    // const [kr, setKr] = useState(room?.blowingBox);
                   return  <div className={"divTableRow"} key={room.id}>
                        <div className="divTableCell">
                            {room.roomName}
                        </div>
                        <div className="divTableCell">
                            {room.area}
                        </div>
                        <div className="divTableCell">
                            {room.volume}
                        </div>
                        <div className="divTableCell">
                            {room?.blowing}
                        </div>
                        <div className="divTableCell">
                            {SelectKR(room?.blowingBox, room?.blowingBoxBeforeChange ?? room?.blowingBox,  room, storeyId, updateKr)} {room?.blowingBox && "kr."}
                        </div>
                        <div className="divTableCell">
                            {room.exhaust}
                        </div>
                        <div className="divTableCell">
                            {SelectKR(room?.exhaustBox, room?.exhaustBoxBeforeChange ?? room?.exhaustBox, room, storeyId, updateKr)} {room?.exhaustBox && "kr."}
                        </div>
                        <div className="divTableCell">
                            {room.replacement}
                        </div>
                    </div>})}
            </div>

        </div>
    </>
}

const StoreyTotal = ({storey}) => {
    return <>
        <div className="titleWrapWrap">
            <div className="nag"><h3>Podsumowanie</h3></div>
        </div>
        <div className="divTable">
            <div className="divTableHeading hidden">
                <div className="divTableRow hidden">
                    <div className="divTableHead">Nazwa pomieszczenie</div>
                    <div className="divTableHead">Powierzchnia pomieszczenia</div>
                    <div className="divTableHead">Kubatura pomieszczenia</div>
                    <div className="divTableHead">Nawiew</div>
                    <div className="divTableHead">Skrzynki rozprężne nawiew</div>
                    <div className="divTableHead">Wywiew</div>
                    <div className="divTableHead">Skrzynki rozprężne wywiew</div>
                    <div className="divTableHead">Krotność wymiany</div>
                </div>
            </div>
            <div className="divTableBody summary">
                <div className="divTableRow">
                    <div className="divTableCell">
                    </div>
                    <div className="divTableCell">
                        {storey.areaTotal}
                    </div>
                    <div className="divTableCell">
                        {storey.volumeTotal}
                    </div>
                    <div className="divTableCell">
                        {storey.blowingTotal}
                    </div>
                    <div className="divTableCell">
                        {addTextKr(storey.blowingBoxTotal)}
                    </div>
                    <div className="divTableCell">
                        {storey.exhaustTotal}
                    </div>
                    <div className="divTableCell">
                        {addTextKr(storey.exhaustBoxTotal)}
                    </div>
                    <div className="divTableCell">
                        {storey.replacementTotal}
                    </div>
                </div>
            </div>
        </div>
        <div className="divTable">
            <div className="divTableHeading hidden">
                <div className="divTableRow hidden">
                    <div className="divTableHead">Nazwa pomieszczenie</div>
                    <div className="divTableHead">Powierzchnia pomieszczenia</div>
                    <div className="divTableHead">Kubatura pomieszczenia</div>
                    <div className="divTableHead">Nawiew</div>
                    <div className="divTableHead">Skrzynki rozprężne nawiew</div>
                    <div className="divTableHead">Wywiew</div>
                    <div className="divTableHead">Skrzynki rozprężne wywiew</div>
                    <div className="divTableHead">Krotność wymiany</div>
                </div>
            </div>
            <div className="divTableBody summary">
                <div className="divTableRow">
                    <div className="divTableCell">
                    </div>
                    <div className="divTableCell">

                    </div>
                    <div className="divTableCell">

                    </div>
                    <div className="divTableCell">

                    </div>
                    <div className="divTableCell">
                        <button>Rozdzielacz nawiew</button>
                    </div>
                    <div className="divTableCell">

                    </div>
                    <div className="divTableCell">
                        <button>Rozdzielacz wywiew</button>
                    </div>
                    <div className="divTableCell">
                    </div>
                </div>
            </div>
        </div>
        {/*<span>Podsumowanie {storey.name}</span>*/}
        {/*<span>{storey.areaTotal}</span>*/}
        {/*<span>{storey.volumeTotal}</span>*/}
        {/*<span>{storey.blowingTotal}</span>*/}
        {/*<span>{storey.blowingBoxTotal} kr.</span>*/}
        {/*<span>{storey.exhaustTotal}</span>*/}
        {/*<span>{storey.exhaustBoxTotal} kr.</span>*/}
        {/*<span>{storey.replacementTotal}</span>*/}
    </>
}

const StoreysTotal = ({totals}) => {
    return <>
        <div className="titleWrapWrap">
            <div className="nag"><h3>Podsumowanie wszystkich kondygnacji</h3></div>
        </div>
        <div className="divTable">
            <div className="divTableHeading"  style={{visibility: "hidden", lineHeight: 0, height: 0}}>
                <div className="divTableRow" style={{height: 0}}>
                    <div className="divTableHead">Nazwa pomieszczenie</div>
                    <div className="divTableHead">Powierzchnia pomieszczenia</div>
                    <div className="divTableHead">Kubatura pomieszczenia</div>
                    <div className="divTableHead">Nawiew</div>
                    <div className="divTableHead">Skrzynki rozprężne nawiew</div>
                    <div className="divTableHead">Wywiew</div>
                    <div className="divTableHead">Skrzynki rozprężne wywiew</div>
                    <div className="divTableHead">Krotność wymiany</div>
                </div>
            </div>
            <div className="divTableBody">
                <div className="divTableRow summary">
                    <div className="divTableCell">
                    </div>
                    <div className="divTableCell">
                        {totals.area}
                    </div>
                    <div className="divTableCell">
                        {totals.volume}
                    </div>
                    <div className="divTableCell">
                        {totals.blowing}
                    </div>
                    <div className="divTableCell">
                        {totals.blowingBox}
                    </div>
                    <div className="divTableCell">
                        {totals.exhaust}
                    </div>
                    <div className="divTableCell">
                        {totals.exhaustBox}
                    </div>
                    <div className="divTableCell">
                        {totals.replacement}
                    </div>
                </div>
            </div>
        </div>
    </>
}



export default CountedRooms
