import {types} from "../types";

const countVolume = ({area, height}) => area*height;

const countExhaust = ({volume, replacementValue, value}) => {
    if (volume*replacementValue <= value) {
        return value
    }
    return volume*replacementValue
}

const countBlowing = ({numberOfPeople, volume}) => {

    const result = numberOfPeople * 30;

    if (result > volume) {
        return result
    }
    return volume;
}
const roundFiveUp = (number) =>  Math.ceil(number/5)*5;

const countRoom = ({room}) => {

    const volume = countVolume({area: room.area, height: room.height});
    if (room.type === types.BLOWING) {
        const blowing = roundFiveUp(countBlowing({volume, numberOfPeople: room.numberOfPeople}));
        return {blowing, replacement: (blowing / volume), volume};
    }

    const exhaust = roundFiveUp(countExhaust({volume, replacementValue: room.replacementValue, value: room.value}));

    return {exhaust, replacement: (exhaust / volume), volume};
}

const countTotal = ({rooms}) => {
    let exhaustTotal = 0;
    let blowingTotal = 0;
    let areaTotal = 0;
    let volumeTotal = 0;
    let replacementTotal = 0;
    let exhaustBoxTotal = 0;
    let blowingBoxTotal = 0;
    rooms.forEach(room => {
        areaTotal += parseFloat(room.area);
        volumeTotal += room.volume;
        replacementTotal += room.replacement;
        exhaustBoxTotal += room?.exhaustBox ?? 0;
        blowingBoxTotal += room?.blowingBox ?? 0;
        if (room.type === types.BLOWING) {
            blowingTotal += room.blowing;
            return;
        }
        exhaustTotal += room.exhaust
    });

    return {exhaustTotal, blowingTotal, areaTotal, volumeTotal, replacementTotal, exhaustBoxTotal, blowingBoxTotal};

}

const countDifferent = ({exhaustTotal, blowingTotal}) => {
    if (exhaustTotal === blowingTotal) {
        return {equalDiff: true}
    }
    if (exhaustTotal < blowingTotal) {
        return {exhaustDiff: blowingTotal-exhaustTotal}
    }
    return {blowingDiff: exhaustTotal-blowingTotal}
}

const howManyBoxes = (number) => {
    if (number < 41) {
        return 1
    }
    if (number > 40) {
        return 2
    }

    return 3
}

const countBoxBlowingOrExhaust = ({rooms}) => {
    return rooms.map(room => {
        if (room?.blowing && room.blowing !== NaN) {
            return {...room, blowingBox: howManyBoxes(room.blowing)}
        }

        return {...room, exhaustBox: howManyBoxes(room.exhaust)}
    });
}

const addDifference = ({diff, orderExhaust, orderBlowing, rooms}) => {

    if (diff?.equalDiff) {
        return rooms;
    }

    const roomsToCheck = [...rooms];

    let {exhaustDiff, blowingDiff} = diff;

    if (exhaustDiff) {
        orderExhaust.forEach( order => {
            if (exhaustDiff <= 0) return;

            const roomsExhaust =  roomsToCheck.filter(room => room.type === types.EXHAUST);
            const room = roomsExhaust.find(room => room.roomId === order);

            if (!room) return;

            exhaustDiff = countRest({room, difference: exhaustDiff, isLast: roomsExhaust.length === 1})
            roomsToCheck.splice(roomsToCheck.indexOf(room), 1);
            const index = rooms.indexOf(room)
            rooms[index] = room;
        });

        roomsToCheck.filter(room => room.types === types.EXHAUST).forEach((room, indexArray,array) => {
            exhaustDiff = countRest({room, difference: exhaustDiff, isLast: array.length - indexArray === 1});

            //  roomsToCheck.splice(roomsToCheck.indexOf(room), 1);

            const index = rooms.indexOf(room)
            rooms[index] = room;

        });

        return rooms
    }

    if (blowingDiff) {
        const roomsBlowing =  roomsToCheck.filter(room => room.type === types.BLOWING);
        orderBlowing.forEach(order => {
            if (blowingDiff <= 0) return;

            const room = roomsBlowing.find(room => room.roomId === order);

            if (!room) return;

            blowingDiff = countRest({room, difference: blowingDiff, isLast: roomsBlowing.length === 1, isBlowing: true});

            roomsBlowing.splice(roomsToCheck.indexOf(room), 1);

            const index = rooms.indexOf(room);
            rooms[index] = room;
        });

        if (blowingDiff <= 0 ) return rooms;

        roomsBlowing.forEach((room, indexArray,array) => {

            blowingDiff = countRest({room, blowingDiff, isLast: array.length - indexArray === 1, isBlowing: true});

          //  roomsToCheck.splice(roomsToCheck.indexOf(room), 1);

            const index = rooms.indexOf(room)
            rooms[index] = room;

        });

        return rooms
    }
}

const countRest = ({room,  difference, isLast, isBlowing = false}) => {
    const blowingOrExhaust = isBlowing ? "blowing" : "exhaust";

    if (isLast) {
        room[blowingOrExhaust] += difference;
        return 0
    }
    const isSingleConnectionBox = room[blowingOrExhaust] / 40 < 1;
    if (isSingleConnectionBox) {
        const rest = 40 - room[blowingOrExhaust];
        if(difference - rest >= 0) {

            room[blowingOrExhaust] += rest;
            difference -= rest;
        }
        else {
            room[blowingOrExhaust] += difference;
            difference = 0;
        }

    }
    else {
        const rest =  Math.ceil(room[blowingOrExhaust] / 80) * 80  - room[blowingOrExhaust];
        if(difference - rest >= 0) {
            room[blowingOrExhaust] = 80;
            difference = difference - rest;
        }
        else {
            room[blowingOrExhaust] += difference;
            difference = 0;
        }
    }

    return difference;
}




export {countVolume, countExhaust, countBlowing, countRoom, countTotal, countDifferent, addDifference, countBoxBlowingOrExhaust};
