import {useState} from "react";
import '../../index.css';
import {useStore, store} from "../../store";
const storey = ({name, height}) => ({name, height});

const CreateProject = () => {
    const  {storeys, nextStep, client, project, addClientAndProject}  = useStore(store, (state) => state);
    const [projectInfo, setProjectInfo] = useState({project, client});

    return <>
        <div className="flex">
            <div className="half"> <label for="project"><span>Nazwa projektu </span><input name="project" id="project" data-test="project" value={projectInfo.project} onChange={(e) => setProjectInfo({...projectInfo, project: e.target.value })}/></label></div>
            <div className="half"> <label for="client"><span>Klient  </span> <input id="client" name="client" data-test="client" value={projectInfo.client} onChange={(e) => setProjectInfo({...projectInfo, client: e.target.value })}/></label></div>
        </div>
        <AddStorey />

        <ListStorey />
        <div className={"flex"}>
            <div></div>
            <button className={"button alt"} onClick={() => { addClientAndProject({client: projectInfo.client, project: projectInfo.project});nextStep(1)}} disabled={!storeys.length}>Przejdź dalej</button>
        </div>


    </>


}

const AddStorey = () => {

    const  {addStorey, storeys}  = useStore(store, (state) => state);

    const addStoreySubmit = (e) => {
        e.preventDefault();
        setInputStates({name: "", height: ""});
        addStorey({name: e.target.name.value, height: e.target.height.value});
        e.target.reset();
    }


    const [inputsState, setInputStates] = useState({name: "", height: ""});
    const onChange = (event) => {
        setInputStates({...inputsState, [event.target.name]: event.target.value})
    }

    const isDisable = inputsState.name.length > 0  && inputsState.height.length > 0;

    return <>
        <form onSubmit={addStoreySubmit} className={"flex flexVend"}>
            <div className={"formInput50"}>
                <label for="name">
                    <span>Nazwa kondygnacji</span>
                    <input name="name" id="name" data-test="storeyName" onChange={onChange}/>
                </label>
            </div>
            <div className={"formInput50"}>
                <label for="height"  className={"formInput50"}>
                    <span>Wysokość [m]</span>
                    <input id="height" name="height" data-test="storeyHeight" onChange={onChange}/>
                </label>
            </div>
            <div className={"formAdd"}>
                <button type={"submit"} disabled={!isDisable}>Dodaj</button>
            </div>
        </form>
    </>
}

const EditStorey = ({storey, setEditId}) => {

    const  {editStorey}  = useStore(store, (state) => state);
    const editStoreyLocal = (e) => {
        e.preventDefault();
        editStorey({id: storey.id, name: e.target.name.value, height: e.target.height.value})
        setEditId(null);
        e.target.reset();
    }
    const [inputsState, setInputStates] = useState({name: storey.name, height: storey.height});

    const inputOnChange = (event) => {
            setInputStates({...inputsState, [event.target.name]: event.target.value})
    }
    const isDisable = inputsState.name.length > 0  && inputsState.height.length > 0;
    return <>
        <form onSubmit={editStoreyLocal} className={"divTableRow"}>
            <div className="divTableCell"> <input name="name" onChange={inputOnChange} id="name" data-test="storeyName" value={inputsState.name} /></div>
            <div className="divTableCell">    <input  onChange={inputOnChange} id="height" name="height" data-test="storeyHeight" value={inputsState.height}/></div>
            <div className="divTableCell"></div>
            <div className="divTableCell">     <button type={"submit"} disabled={!isDisable} >Zapisz</button></div>

        </form>
    </>
}

const ListStorey = () => {
    const  {removeStorey, storeys}  = useStore(store, (state) => state);
    const [editId, setEditId] = useState(null);

    return <>
         <div className="divTable">
             {storeys.length > 0 && <div className="divTableHeading">
                <div className="divTableRow">
                    <div className="divTableHead">Nazwa kondygnacji</div>
                    <div className="divTableHead">Wysokość</div>
                    <div className="divTableHead">Edytuj</div>
                    <div className="divTableHead">Usuń</div>
                </div>
            </div>}

            <div className="divTableBody">

                {storeys.map( (storey, index) => {
                    if (editId === storey.id) {
                      return <EditStorey key={`${storey}${index}`} storey={storey} setEditId={setEditId}/>
                    }
                    return <div className={"divTableRow"} key={`${storey}${index}`}><div className="divTableCell">{storey.name}</div> <div className="divTableCell">{storey.height}</div>  <div className="divTableCell"><button onClick={() => setEditId(storey.id)}>Edytuj</button> </div>  <div className="divTableCell"><button onClick={() => removeStorey({id: storey.id})}>Usuń</button></div></div>
                })}

            </div>
        </div>

    </>
}

export default CreateProject;
