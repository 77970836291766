import {useCallback, useSyncExternalStore} from "react";
import {addDifference, countBoxBlowingOrExhaust, countDifferent, countRoom, countTotal} from "./countBlowingExhaust";

const createStore = (createState) => {
    const getState = () => state;
    const setState = (nextState) => {
        state =
            typeof nextState === "function"
                ? nextState(state)
                : nextState;
        listeners.forEach((listener) => {
            listener();
        });
    };
    let state = createState(setState);
    const listeners = new Set();
    const subscribe = (listener) => {
        listeners.add(listener);
        return () => listeners.delete(listener);
    };
    return { getState, setState, subscribe };
};

const identity = (x) => x;

const useStore = (store, selector = identity) => {
    return useSyncExternalStore(
        store.subscribe,
        useCallback(() => selector(store.getState()), [
            store,
            selector
        ])
    );
};

const sumTotal = (storeyTotal, total) => {
    total.area += storeyTotal.areaTotal;
    total.blowingBox += storeyTotal.blowingBoxTotal;
    total.blowing += storeyTotal.blowingTotal;
    total.exhaustBox += storeyTotal.exhaustBoxTotal;
    total.exhaust += storeyTotal.exhaustTotal;
    total.replacement += storeyTotal.replacementTotal;
    total.volume += storeyTotal.volumeTotal;
}




const store = createStore((setState) => ({
    client: "",
    project: "",
    storeys: [],
    storeysCounted: [],
    step: 0,
    totals: {},
    device: {direction: null, connection: null},
    addClientAndProject: ({client, project}) => {
        setState((prev) => {return {...prev, client, project}})
    },
    selectDevice: ({direction, connection}) => {
        setState((prev) => {
            let {device} = prev;
            if (connection) {
                device = {...device, connection};
            } else {
                device = {...device, direction};
            }
            return {...prev, device}})
    },
    nextStep: (num) => {setState((prev) => ({...prev, step: num}))},
    addStorey: ({name, height}) => {
        setState((prev) => {
            const id  =  prev.storeys.length === 0 ? 0 : (prev.storeys.reduce((acc, item) => acc = acc > item.id ? acc : item.id, 0)+1);
            return {
            ...prev,
            storeys: [...prev.storeys, {id, name, height}]
        }});
    },
    editStorey: ({id, name, height}) => {
        setState((prev) => {
            const storeys = prev.storeys.map( storeyToReplace =>  {
                if (storeyToReplace.id === id) {
                    return {id, name, height}
                }
                return storeyToReplace;
            });

            return {
                ...prev,
                storeys: [...storeys]
            }});
    },
    removeStorey: ({id}) => {
        setState((prev) => ({
            ...prev,
            storeys: [...prev.storeys.filter( storey =>  storey.id !== id)]
        }));
    },
    addRoomToStorey: ({idStorey, room}) => {
        setState((prev) => {
            const storeys = prev.storeys.map( storeyToReplace =>  {
                if (storeyToReplace.id === idStorey) {
                    let idRoom = 0;
                    let rooms = [];
                    if (!storeyToReplace?.rooms) {
                        rooms = [{...room, id: idRoom}];

                    } else {
                        idRoom = storeyToReplace.rooms.reduce((acc, item) => acc = acc > item.id ? acc : item.id, 0)+1;
                        rooms = [...storeyToReplace.rooms, {...room, id: idRoom}];
                    }

                    return {...storeyToReplace, rooms}
                }
                return storeyToReplace;
            });

            return {...prev, storeys};
        })
    },
    updateDistributor: ({storeyId, roomId, value}) => {
        setState((prev) => {
            const total = {
                area: 0,
                blowingBox: 0,
                blowing: 0,
                exhaustBox: 0,
                exhaust: 0,
                replacement: 0,
                volume: 0
            };

            const storeysCounted = prev.storeysCounted.map( storey =>  {
                if (storey.id === storeyId) {
                    let power = "up" | "left";
                    let connection = "";
                    //"DN160"; "DN200" "200x50"
                    let up;
                    let bottom;
                    let left;
                    let right;

                    //products list



                    return  {...storey}
                }
                return storey;
            });

            return {
                ...prev,
                storeysCounted
            }});
    },
    updateKr: ({storeyId, roomId, value}) => {
        setState((prev) => {
            const total = {
                area: 0,
                blowingBox: 0,
                blowing: 0,
                exhaustBox: 0,
                exhaust: 0,
                replacement: 0,
                volume: 0
            };

            const storeysCounted = prev.storeysCounted.map( storey =>  {
                if (storey.id === storeyId) {
                    const rooms = storey.rooms.map( roomToUpdate => {
                        if (roomToUpdate.id === roomId) {
                            if (roomToUpdate.blowingBox) {
                                if (!roomToUpdate?.blowingBoxBeforeChange) {
                                    roomToUpdate.blowingBoxBeforeChange = roomToUpdate.blowingBox
                                }
                                roomToUpdate.blowingBox = value;
                                return roomToUpdate
                            }
                            if (!roomToUpdate?.exhaustBoxBeforeChange) {
                                roomToUpdate.exhaustBoxBeforeChange = roomToUpdate.exhaustBox
                            }
                            roomToUpdate.exhaustBox = value;
                            return roomToUpdate
                        }
                        return roomToUpdate;

                    });

                    storey.rooms = [...rooms];
                    const storeyTotal = countTotal({rooms});
                    sumTotal(storeyTotal, total);

                    return  {...storey, rooms, ...storeyTotal}
                }
                return storey;
            });

            return {
                ...prev,
                storeysCounted
            }});
    },
    updateRoom: ({storeyId, room}) => {
        setState((prev) => {
            const storeys = prev.storeys.map( storey =>  {
                if (storey.id === storeyId) {
                    const rooms = storey.rooms.map( roomToUpdate => {
                        if (roomToUpdate.id === room.id) {
                            return room
                        }
                        return roomToUpdate;

                    });

                    storey.rooms = [...rooms];
                    return storey
                }
                return storey;
            });

            return {
                ...prev,
                storeys
            }});
    },
    countStoreys: () => {
        setState((prev) => {
            const orderExhaust = [2, 8, 9, 10, 11, 12, 13, 14, 15];
            const orderBlowing = [1, 3, 4, 5, 6, 7];
            const total = {
                area: 0,
                blowingBox: 0,
                blowing: 0,
                exhaustBox: 0,
                exhaust: 0,
                replacement: 0,
                volume: 0
            }

            const storeys = prev.storeys.map( storey =>  {

                    const storeyRooms = storey.rooms.map(room => ({...room, ...countRoom({room})}));


                    let {exhaustTotal, blowingTotal, areaTotal, volumeTotal, replacementTotal} = countTotal({rooms: storeyRooms})

                    const roomsCounted = countBoxBlowingOrExhaust({rooms: addDifference({diff: countDifferent({
                            exhaustTotal,
                            blowingTotal
                        }), orderExhaust, orderBlowing, rooms: storeyRooms})});


                //areaTotal, volumeTotal, replacementTotal
                const storeyTotal = countTotal({rooms: roomsCounted});
                sumTotal(storeyTotal, total);

                return {...storey, rooms: roomsCounted, ...storeyTotal};

            });

            return {
                ...prev,
                storeysCounted: storeys,
                totals: total
            }});

    },

    removeRoom: ({storeyId, roomId}) => {
        setState((prev) => {
            const storeys = prev.storeys.map( storey =>  {

                if (storey.id === storeyId) {
                    const rooms = storey.rooms.filter( room => room.id !== roomId);

                    storey.rooms = [...rooms];
                    return storey
                }
                return storey;
            });

            return {
            ...prev,
            storeys
        }});
    },
    findProducts: () => {
        setState((prev) => {
           const {totals} = prev;
            const maxBox = totals.blowingBox + totals.exhaustBox;
        });
    },
    inc2: () => {
        setState((prev) => ({
            ...prev,
            count2: prev.count2 + 1
        }));
    }
}));

export {useStore, store};
