import {store, useStore} from "../../store";
import {useEffect, useRef} from "react";
const Distributor = () => {
    const  {totals, selectDevice, device, nextStep}  = useStore(store, (state) => state);
    console.log("====storeysCounted", device, totals);
    const directions = [
        {id: 0, name: "z góry/z boku"},
        {id: 1, name: "z boku"}
    ];

    const connections = [
        {id: 0, name: "200x50", max: 150},
        {id: 1, name: "DN160", max: 290},
        {id: 2, name: "DN200", max: 10000},
    ];
    const isFirst = useRef(false);

    const directionsMapped = directions.map((option, index) => <option key={index} value={index}>{option.name}</option>);
    const connectionsMapped = connections.filter(connection => totals.blowing < connection.max).map((option, index) =>
        <option key={index} value={index}>{option.name}</option>);

    useEffect(() => {
        if (isFirst.current) return
        selectDevice({connection: connections.filter(connection => totals.blowing < connection.max)[0], direction: directions[0]});
        isFirst.current = true;
    }, [])

    return <>
        <div className={"flex"}>
            <div className="formInput30">
                <label htmlFor="strumien">Strumień powietrza<input type={"text"} defaultValue={totals.blowing}/></label>
                <div className="unit">m3/h</div>
            </div>

            <div className="formInput30">
                <label htmlFor="zasilanie">Zasilanie<select name="direction" id="direction"
                                                            onChange={(event) => selectDevice({direction: directions[+event.target.value]})}>
                    {directionsMapped}
                </select></label>
            </div>

            <div className="formInput30">
                <label htmlFor="przylacze">Przyłącze<select name="connection" id="connection" onChange={(event) => selectDevice({connection: connections[+event.target.value]})}>
                    {connectionsMapped}
                </select></label>
            </div>
        </div>

        <div className={"flex"}>
            <button onClick={() => {nextStep(2)}} >wstecz</button>
            <button onClick={() => { nextStep(4)}} >Przejdź do rozdzielacza</button>
        </div>
    </>
}

export default Distributor;
