const options = [
    {id: 0, name: "Rodzaj pomieszczennia"},
    {
        "id": 4,
        "name": "Pokój",
        "type": "nawiew"
    },
    {
        "id": 1,
        "name": "Sypialnia",
        "type": "nawiew"
    },
    {
        "id": 3,
        "name": "Salon",
        "type": "nawiew"
    },
    {
        "id": 5,
        "name": "Gabinet",
        "type": "nawiew"
    },
    {
        "id": 6,
        "name": "Biuro",
        "type": "nawiew"
    },
    {
        "id": 7,
        "name": "Jadalnia",
        "type": "nawiew"
    },
    {
        "id": 2,
        "name": "Kuchnia",
        "type": "wywiew",
        "value": 70,
        "replacementValue": 2
    },
    {
        "id": 8,
        "name": "Łazienka",
        "type": "wywiew",
        "value": 50,
        "replacementValue": 3
    },
    {
        "id": 9,
        "name": "Toaleta",
        "type": "wywiew",
        "value": 30,
        "replacementValue": 3
    },
    {
        "id": 10,
        "name": "WC",
        "type": "wywiew",
        "value": 30,
        "replacementValue": 3
    },
    {
        "id": 11,
        "name": "Pralnia",
        "type": "wywiew",
        "value": 15,
        "replacementValue": 2
    },
    {
        "id": 12,
        "name": "Wiatrołap",
        "type": "wywiew",
        "value": 15,
        "replacementValue": 1
    },
    {
        "id": 13,
        "name": "Pomieszczenie Gospodarcze",
        "type": "wywiew",
        "value": 15,
        "replacementValue": 1
    },
    {
        "id": 14,
        "name": "Spiżarnia",
        "type": "wywiew",
        "value": 15,
        "replacementValue": 1
    },
    {
        "id": 15,
        "name": "Garderoba",
        "type": "wywiew",
        "value": 15,
        "replacementValue": 1
    }];

export default options;
