const List = ({options,setSelectedHandle, defaultValue}) => {

    const optionMapped = options.map((option, index) => <option key={index} value={index}>{option.name}</option>);

    return <select name="rooms" id="rooms" onChange={(event) => setSelectedHandle(options[+event.target.value])} defaultValue={defaultValue ?? ""}>
        {optionMapped}
    </select>
}

export default List;
